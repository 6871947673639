body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f8f9!important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.text-align-center .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}

.text-align-center .MuiDataGrid-columnHeaderTitle {
  width: 100%;
}

.boldHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 900;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.height-100 .MuiBottomNavigation-root {
  height: auto;
}
.position-manangement-modal .MuiBox-root{
  height: 98vh;
  max-height: 98vh;
  padding: 15px 25px;
}
.hiddenButton {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  width: 550px;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.1);
}
